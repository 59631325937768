<template>
	<div class="loading-area">
		<div class="goods_top">
			<el-card>
				<div class="global-search-box">
					<!-- <div class="search-top-button">
					    <el-button type="primary" v-if="hasPerm(['goods.store'])" @click="addshopgoods">+创建商品</el-button>
					    <el-button type="primary" v-if="hasPerm(['collection.goods.store'])" @click="gather">采集商品</el-button>
					</div> -->
					<div class="search-content">
						<div class="search-item">
							<el-input :placeholder="shopnamesku == 1 ? '请输入商品名称' : '请输入商品SKU编号'" v-model="goodsName">
								<template slot="prepend">
									<el-select v-model="shopnamesku" placeholder="请选择">
										<el-option v-for="(item, index) in shopaddList" :key="item.value"
											:label="item.label" :value="item.value"></el-option>
									</el-select>
								</template>
							</el-input>
						</div>
						<div class="search-item">
							<label class="search-label">商品类型：</label>
							<el-select v-model="typevalue" clearable filterable placeholder="全部">
								<el-option v-for="item in common_info['goods.vr_type.list']" :key="item.key"
									:label="item.value" :value="item.key">
								</el-option>
							</el-select>
						</div>
						<div class="search-item">
							<label class="search-label">商品分类：</label>
							<el-cascader placeholder="请选择分类" v-model="catvalue" :options="catlist" filterable clearable
								:props="{ value: 'id', label: 'label', children: 'children' }">
							</el-cascader>
						</div>
						<div class="search-item">
							<label class="search-label">品牌筛选：</label>
							<el-select v-model="brandvalue" filterable clearable placeholder="全部" remote
								:remote-method="remoteMethoded" @visible-change="templateTagChange">
								<el-option v-for="item in brandlist" :key="item.id" :label="item.name" :value="item.id">
								</el-option>
								<template>
									<div class="purpagebutkong"></div>
									<div class="purpagebut">
										<el-pagination :small="true" @current-change="handclassfly" hide-on-single-page
											:current-page="brandPage" :page-size="10" layout="prev, next"
											:total="brandtotal_entry">
										</el-pagination>
									</div>
								</template>
							</el-select>
						</div>
						<div class="search-item">
							<label class="search-label">创建时间：</label>
							<el-date-picker v-model="createvalue" type="datetimerange" start-placeholder="开始日期"
								format="yyyy-MM-dd" :default-time="['00:00:00', '23:59:59']" end-placeholder="结束日期">
							</el-date-picker>
						</div>
						<template v-if="is_advanced">
							<div class="search-item">
								<label class="search-label">商品分组：</label>
								<el-select v-model="groupvalue" clearable filterable placeholder="全部">
									<el-option v-for="item in grouplist" :key="item.id" :label="item.name" :value="item.id">
									</el-option>
								</el-select>
							</div>
							<div class="search-item">
								<label class="search-label">商品来源：</label>
								<el-select v-model="sourcevalue" clearable placeholder="全部">
									<el-option v-for="item in common_info['goods.source.type.list']" :key="item.key"
										:label="item.value" :value="item.key">
									</el-option>
								</el-select>
							</div>
							<div class="search-item">
								<label class="search-label">商品销量：</label>
								<div class="number-of-intervals">
									<el-input-number :min="minumber" :max="100000000" v-model="low_sales" :controls="false">
									</el-input-number>
									<span>-</span>
									<el-input-number type="number" :min="minumber" :max="100000000" v-model="high_sales"
										:controls="false">
									</el-input-number>
								</div>
							</div>
							<div class="search-item">
								<label class="search-label">商品价格：</label>
								<div class="number-of-intervals">
									<el-input-number type="number" :min="minumber" :max="99999999.99" v-model="low_price"
										:controls="false">
									</el-input-number>
									<span>-</span>
									<el-input-number type="number" :min="minumber" :max="99999999.99" v-model="high_price"
										:controls="false">
									</el-input-number>
								</div>
							</div>
						</template>
						<div class="high_headline" @click="screen">
							<span>高级筛选</span>
							<i class="el-icon-arrow-down" v-if="!is_advanced"></i>
							<i class="el-icon-arrow-up" v-else></i>
						</div>
					</div>
					<div class="serach-buttom-button">
						<el-button type="primary" @click="goodseacrh">搜索</el-button>
						<el-button @click="resetting">重置条件</el-button>
						<el-button type="primary" @click.stop="pullgoods">拉取商品</el-button>
						<el-button type="primary" @click="exportTable">导出商品</el-button>
					</div>
				</div>
			</el-card>

			<el-card>

				<div class="goods_nav">
					<div class="goods_card">
						<div class="nav_headline">
							<el-tabs v-model="activeName" @tab-click="handleClick">
								<el-tab-pane label="全部"></el-tab-pane>
								<el-tab-pane v-for="(item, index) in common_info['goods.store_status.list']" :key="index"
									:label="item.value"></el-tab-pane>
							</el-tabs>
						</div>
						<div class="goods_table" ref="goods_table">
							<div class="table_head d-flex align-items-center" v-if="tableData && tableData.length">
								<el-checkbox v-model="all_checked" @change="allchange"></el-checkbox>
								<p class="page_num">当前页全选</p>
								<el-button class="grounding" @click="groundlist()" v-if="hasPerm(['goods.on'])">上架
								</el-button>
								<el-button class="grounding" @click="allshelf()" v-if="hasPerm(['goods.off'])">下架
								</el-button>
								<el-button class="grounding" @click="changepice()" v-if="hasPerm(['goods.update.price'])">调价
								</el-button>
								<el-button class="grounding" @click="changegroup()" v-if="hasPerm(['goods.update.group'])">
									改分组</el-button>
								<el-button class="grounding" @click="goodsdelect()" v-if="hasPerm(['goods.delete'])">删除
								</el-button>
								<el-select v-model="cascadervalue" placeholder="请选择" class="foot_cascader foot_cascadered"
									@change="cascChange">
									<el-option v-for="(item, index) in cascoptions" :key="item.value" :label="item.label"
										:value="item.value"
										:disabled="item.permission ? !hasPerm(item.permission) : false"></el-option>
								</el-select>
							</div>
							<div class="goodtablist">
								<el-table :data="tableData" style="width: 100%" ref="multipleTable"
									@selection-change="singletable" @sort-change="changesort">
									<el-table-column type="selection" min-width="55"></el-table-column>
									<el-table-column min-width="100" prop="id" label="ID"></el-table-column>
									<el-table-column min-width="150" prop="cat_name" label="商品分类">
									</el-table-column>
									<el-table-column min-width="200" prop="sku" label="SKU">
									</el-table-column>
									<el-table-column label="商品信息" min-width="360">
										<template slot-scope="scope">
											<div class="goods_detail d-flex align-items-center">
												<div class="goods_detail_img" @click.stop="goodDetail(scope)">
													<img :src="scope.row.cover_pic" alt="" :onerror="errorImg" />
												</div>
												<div class="table_name" @click.stop="goodDetail(scope)">
													<span v-if="scope.row.vr_type == 0" class="pt">{{
														common_info["goods.vr_type.map"][scope.row.vr_type] }}</span>
													<span v-else-if="scope.row.vr_type == 1" class="xn">{{
														common_info["goods.vr_type.map"][scope.row.vr_type] }}</span>
													{{ scope.row.name }}
												</div>
												<div class="table_iconed">
													<el-popover placement="top" width="515" trigger="click"
														:ref="'nameVisible' + scope.row.id">
														<div class="popover">
															<div class="popover_wrap d-flex align-items-center">
																<div class="popover_input">
																	<el-input v-model="scope.row.name"></el-input>
																</div>
																<div class="popover_group">
																	<span
																		@click.stop="modifyname(scope.row.id, scope.row.name)"
																		v-if="hasPerm(['goods.update.name'])">保存</span>
																	<span @click.stop="nameCancel(scope)">取消</span>
																</div>
															</div>
														</div>
														<div class="goodcompile" slot="reference">
															<i class="el-icon-edit"></i>
														</div>
													</el-popover>
												</div>
											</div>
										</template>
									</el-table-column>
									<el-table-column label="售价" sortable min-width="150">
										<template slot-scope="scope">
											<div>
												{{ Number(scope.row.price).toFixed(2) }}
											</div>
										</template>
									</el-table-column>
									<el-table-column label="成本价" sortable min-width="150">
										<template slot-scope="scope">
											<div>
												{{ Number(scope.row.cost_price).toFixed(2) }}
											</div>
										</template>
									</el-table-column>
									<el-table-column label="库存" prop="stock" sortable min-width="150">
									</el-table-column>
									<el-table-column label="热卖/推荐" prop="stock" min-width="150">
										<template slot-scope="scope">
											<div class="hottj">
												<p>
													热卖：<span :class="scope.row.is_hot == 1 ? 'active' : ''"
														@click="hotclick(scope.row.id, scope.row.is_hot)">{{
															scope.row.is_hot == 0 ? "否" : "是" }}</span>
												</p>
												<p>
													推荐：<span :class="scope.row.is_best == 1 ? 'active' : ''"
														@click="bestclick(scope.row.id, scope.row.is_best)">{{
															scope.row.is_best == 0 ? "否" : "是" }}</span>
												</p>
											</div>
										</template>
									</el-table-column>
									<el-table-column label="销量" prop="virtual_sales" sortable min-width="150">
									</el-table-column>
									<el-table-column label="是否上架" min-width="150">
										<template slot-scope="scope">
											<div>
												<el-switch v-model="scope.row.status" :active-value="1" :inactive-value="0"
													@change="grounding($event, scope)" inactive-color="#bbb">
												</el-switch>
											</div>
										</template>
									</el-table-column>
									<el-table-column label="排序" prop="sort" min-width="150">
									</el-table-column>
									<el-table-column label="来源" min-width="150">
										<template slot-scope="scope">
											<div class="table_source"
												:style="{ backgroundColor: scope.row.color ? scope.row.color.split(',')[0] : '', color: scope.row.color ? scope.row.color.split(',')[1] : '' }">
												{{ common_info["goods.source.type.map"][scope.row.source] }}
											</div>
										</template>
									</el-table-column>
									<el-table-column label="操作" fixed="right" width="200">
										<template slot-scope="scope">
											<div class="caozuo">
												<!-- <div>复制</div> -->
												<div @click="goodsedit(scope.row.id)" v-if="hasPerm(['goods.update'])">
													修改
												</div>
												<!-- <div @click="bindspu(scope.row.id)" v-if="hasPerm(['goods.spu.store'])">
													绑定SPU
												</div> -->
												<div @click="goodslog(scope.row.id)">日志</div>
												<div @click="goodsdelected(scope.row.id)" v-if="hasPerm(['goods.delete'])">
													删除
												</div>
											</div>
										</template>
									</el-table-column>

									<template slot="empty">
										<div class="empty_data">
											<p>暂无数据</p>
										</div>
									</template>
								</el-table>
							</div>
						</div>
						<div class="table_foot">
							<div class="foot_left d-flex align-items-center">
								<el-checkbox v-model="all_checked" @change="allchange"></el-checkbox>
								<p class="page_num">共{{ pagesNumber }}条</p>
								<el-button class="grounding" @click="groundlist()" v-if="hasPerm(['goods.on'])">上架
								</el-button>
								<el-button class="grounding" @click="allshelf()" v-if="hasPerm(['goods.off'])">下架
								</el-button>
								<el-button class="grounding" @click="changepice()" v-if="hasPerm(['goods.update.price'])">调价
								</el-button>
								<el-button class="grounding" @click="changegroup()" v-if="hasPerm(['goods.update.group'])">
									改分组</el-button>
								<el-button class="grounding" @click="goodsdelect()" v-if="hasPerm(['goods.delete'])">删除
								</el-button>
								<el-select v-model="cascadervalue" placeholder="请选择" class="foot_cascader"
									@change="cascChange">
									<el-option v-for="(item, index) in cascoptions" :key="item.value" :label="item.label"
										:value="item.value"
										:disabled="item.permission ? !hasPerm(item.permission) : false"></el-option>
								</el-select>
							</div>
							<div class="main_pages">
								<el-pagination @size-change="handleSizeChange" hide-on-single-page
									@current-change="handleCurrentChange" :current-page.sync="currentPage"
									:page-sizes="[10, 20, 30, 40]" :page-size="eachPage"
									layout="sizes, prev, pager, next, jumper" :pager-count="5" :total="total_entry">
								</el-pagination>
							</div>
						</div>
					</div>
				</div>
			</el-card>
		</div>

		<!-- 改价弹层 -->
		<changePrice ref="changepice" :picelist="multipleSelection" @showCityName="showCityName"></changePrice>
		<!-- 改分组 -->
		<el-dialog title="改分组" class="grounptc" :visible.sync="grouptype">
			<div class="grounptcbox">
				<div class="grounptcboxup clearfloat">
					<el-input placeholder="搜索分组名称" v-model="grouptext" ref="groupsearchtext"></el-input>
					<button @click="groupsearch()">搜索</button>
				</div>
				<div class="grounpbutlist">
					<el-checkbox-group v-model="checkList">
						<el-checkbox v-for="(item, index) in grouplist" :key="index" :label="item.id">{{ item.name }}
						</el-checkbox>
					</el-checkbox-group>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="cleargroup()">取 消</el-button>
				<el-button type="primary" @click="sealgroup()">确定</el-button>
			</div>
		</el-dialog>
		<!-- 转移分类 -->
		<div class="catbox">
			<el-dialog title="转移分类" class="catboxtc" :visible.sync="catboxtype" @close="closeDialog">
				<div class="catboxcon">
					<el-cascader placeholder="请选择或搜索分类" v-model="catvaluetc" :options="catlist" filterable clearable
						:props="{ value: 'id', label: 'label', children: 'children' }">
					</el-cascader>
				</div>
				<div slot="footer" class="dialog-footer">
					<el-button @click="clearcatbox()">取 消</el-button>
					<el-button type="primary" @click="sealcat()">确定</el-button>
				</div>
			</el-dialog>
		</div>

		<!-- 日志弹层 -->
		<el-dialog title="操作日志" class="journaltc" :visible.sync="journaltype">
			<div class="journalbox">
				<div class="batchtable">
					<div class="batchtablecon">
						<table>
							<thead>
								<tr>
									<th class="tableleft">事件</th>
									<th class="tableleft">员工</th>
									<th>时间</th>
								</tr>
							</thead>
							<colgroup>
								<col style="width: 40%" />
								<col style="width: 30%" />
								<col style="width: 30%" />
							</colgroup>
							<tbody>
								<tr v-for="(item, index) in loglist" :key="index">
									<td class="tableleft">{{ item.req_route }}</td>
									<td class="tableleft">{{ item.opt_user }}</td>
									<td>{{ item.created_at }}</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="orderlastbut clearfloat" style="padding-bottom: 10px">
						<div class="orderlastbutright">
							<el-pagination @size-change="logChange" hide-on-single-page @current-change="logCurrentChange"
								:current-page.sync="pages" :page-sizes="[10, 20, 30, 40]" :page-size="limit"
								layout="sizes, prev, pager, next, jumper" :pager-count="5" :total="logtotal">
							</el-pagination>
						</div>
					</div>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="journaltype = false">取 消</el-button>
				<el-button @click="journaltype = false" type="primary">确 定</el-button>
			</div>
		</el-dialog>
		<shopselector ref="shopList" @change="goodshoplist($event)" :is_pull="shopPull"
			:pull_goods_url="'/open/select/goods'" :pull_class_url="'/open/select/category'"
			:pull_brand_url="'/open/select/brand'" :pull_group_url="'/open/select/group'">
		</shopselector>

		<!-- 修改品牌 -->
		<el-dialog title="修改品牌" class="amendbrand" :visible.sync="brandVisible">
			<div class="brand_content d-flex align-items-center justify-content-center">
				<p>品牌：</p>
				<el-select v-model="brandModel" filterable clearable placeholder="请选择品牌" remote
					:remote-method="remoteAmendhoded" @visible-change="amendTagChange">
					<el-option v-for="(item, index) in brandAmendlist" :key="item.id" :label="item.name"
						:value="item.id"></el-option>

					<template>
						<div class="purpagebutkong"></div>
						<div class="purpagebut">
							<el-pagination :small="true" @current-change="amendclassfly" hide-on-single-page
								:current-page="brandAmendPage" :page-size="10" layout="prev, next"
								:total="brandtotal_amend">
							</el-pagination>
						</div>
					</template>
				</el-select>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="brandVisible = false">取 消</el-button>
				<el-button @click="brandChange" type="primary">确 定</el-button>
			</div>
		</el-dialog>
		<!-- 修改库存 -->
		<el-dialog title="修改库存" class="inventory" :visible.sync="repertoryVisible">
			<div class="repertory_content d-flex align-items-center justify-content-center">
				<p>库存：</p>
				<el-input type="number" v-model.number="repertoryModel" clearable placeholder="请输入库存"
					@input="repertoryInput"></el-input>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="repertoryVisible = false">取 消</el-button>
				<el-button @click="repertoryChange" type="primary">确 定</el-button>
			</div>
		</el-dialog>

		<!-- 导出 -->
		<diy-export-more ref="diyExportMore" dialogTitle="导出商品" dialogExportKeys="goods_export.field.list"
			dialogExportUrl="/goods/export" :dialogExportIsSearch="true"></diy-export-more>
	</div>
</template>
<script>
export default {
	inject: ["reload"],
	data() {
		return {
			goodlogid: "",
			journaltype: false,
			loglist: [],
			pages: 1,
			limit: 10,
			logtotal: 0,
			minumber: null,
			errorImg: 'this.src="' + require("../../assets/images/lodingsb.svg") + '"', //图片加载失败
			shopnamesku: "1", //商品名称或SKU
			shopnameval: "",
			shopaddList: [{
				label: "商品名称",
				value: "1",
			},
			{
				label: "商品SKU",
				value: "2",
			},
			],
			goodsName: "", //商品名称
			typevalue: "", //商品类型
			is_advanced: false, //是否展开高级搜索
			low_price: 0, //低价
			high_price: 0, //高价
			low_sales: 0, //低销量
			high_sales: 0, //高销量
			currentPage: 1,
			eachPage: 10,
			total_entry: 0,
			all_checked: false,
			multipleSelection: [], //表格中选中的数据
			pagesNumber: 0,
			cascadervalue: "", //更多操作
			cascoptions: [{
				value: "class",
				label: "转移分类",
				permission: ["goods.update.category"],
			},
			// {
			// 	value: "sethot",
			// 	label: "设置热卖",
			// 	permission: ["goods.update.hot"],
			// },
			// {
			// 	value: "closehot",
			// 	label: "取消热卖",
			// 	permission: ["goods.update.hot"],
			// },
			// {
			// 	value: "settuijian",
			// 	label: "设置推荐",
			// 	permission: ["goods.update.best"],
			// },
			// {
			// 	value: "closetui",
			// 	label: "取消推荐",
			// 	permission: ["goods.update.best"],
			// },
			// {
			// 	value: "goodsexport",
			// 	label: "商品导出",
			// },
			{
				value: "goodsbrand",
				label: "修改品牌",
				permission: ["goods.update.brand"],
			},
			{
				value: "goodsStock",
				label: "修改库存",
				permission: ["goods.update.stock"],
			},
			],
			activeName: 0,
			grouptext: "",
			tableData: [], //列表数据
			idlist: [],
			tabstatus: "", //tab切换
			grouptype: false,
			catboxtype: false,
			checkList: [],
			goodprop: "id",
			goodsort: "desc",
			sourcevalue: "", //商品来源
			createvalue: "", //创建时间
			catvalue: "", //商品分类
			catlist: [], //商品分类列表
			brandvalue: "", //品牌筛选
			brandlist: [],
			groupvalue: "", //商品分组列表
			grouplist: [],
			keyword: "",
			catvaluetc: "",
			keywordeds: "",
			mobileActive: this.$route.query.active_id || this.$route.query.activity_id || '0',
			shopPull: true,	//是否为商品拉取
			brandVisible: false,	//品牌弹窗
			brandModel: '',	//品牌选择
			repertoryModel: 0,
			repertoryVisible: false,	//库存选择
			brandPage: 1,
			brandtotal_entry: 0,
			brandText: '',
			brandAmendlist: [],
			brandAmendPage: 1,
			brandtotal_amend: 0,
			brandamendText: '',
			common_info: {}
		};
	},
	created() {
		let data = {
			keys: [
				"goods.source.type.list",
				"goods.source.type.map",
				"goods.status.list",
				"goods.vr_type.list",
				"goods.vr_type.map",
				"goods.store_status.list",
			],
		};
		this.common.getCommonInfo(this, data, res => {
			this.common_info = res;
		});
	},
	// 确保详情修改完成之后及时刷新页面
	activated() {
		setTimeout(() => {
			// 获取员工列表
			this.goodslistapi()
		}, 0);
	},
	mounted: function() {
		this.goodslistapi();
		this.argumentCatlist();
		this.brandlistapi();
		this.grouplistapi();
		this.amendBrand();
	},
	methods: {
		goodslog(id) {
			this.goodlogid = id;
			this.journaltype = true;
			this.goodslogapi(id);
		},
		logChange(val) {
			this.limit = val;
			this.goodslogapi(this.goodlogid);
		},
		logCurrentChange(val) {
			this.pages = val;
			this.goodslogapi(this.goodlogid);
		},
		goodslogapi(id) {
			let data = {
				page: this.pages,
				limit: this.limit,
			};
			this.$get(this.$apis.goodslog + id, data)
				.then((res) => {
					//console.log(res)
					if (res.code == 200) {
						this.loglist = res.data.list;
						this.logtotal = res.data.total;
					} else {
						this.common.message(this, res.message);
					}
				})
				.catch((err) => {
					// //console.log(err)
					this.common.message(this, err.message);
				});
		},
		screen() {
			this.is_advanced = !this.is_advanced;
		},
		gather() {
			this.$router.push("/collection/goods");
		},
		// 是否热卖
		hotclick(id, hot) {
			if (!this.hasPerm(["goods.update.hot"])) {
				this.$message({
					type: "warning",
					message: "暂无权限",
				});
				return false;
			}
			this.idlist = [];
			if (hot == 0) {
				this.idlist.push(id);
				this.goodshot(1, this.idlist);
			} else {
				this.idlist.push(id);
				this.goodshot(0, this.idlist);
			}
		},
		// 是否推荐
		bestclick(id, best) {
			if (!this.hasPerm(["goods.update.best"])) {
				this.$message({
					type: "warning",
					message: "暂无权限",
				});
				return false;
			}
			this.idlist = [];
			if (best == 0) {
				this.idlist.push(id);
				this.goodbest(1, this.idlist);
			} else {
				this.idlist.push(id);
				this.goodbest(0, this.idlist);
			}
		},
		// 创建商品
		addshopgoods() {
			this.$router.push({
				name: "goods_add",
				params: {
					id: 0,
				},
			});
		},
		// 修改商品
		goodsedit(id) {
			this.$router.push({
				name: "goods_add",
				params: {
					id: id,
				},
			});
		},
		// 绑定spu
		bindspu(id) {
			this.$router.push({
				name: "goods_addspu",
				params: {
					id: id,
				},
			});
		},
		exportTable() {
			this.$refs.diyExportMore.exportMore = true
		},
		// 提取搜索数据
		extractSearch() {
			let data = {
				search: [],
				order: [{
					key: this.goodprop,
					value: this.goodsort,
				},],
				page: this.currentPage,
				limit: this.eachPage,
			};
			if (this.goodsName) {
				let obj = {
					key: this.shopnamesku == 1 ? "name" : "sku",
					value: this.goodsName,
					op: "search",
				};
				data.search.push(obj);
			}
			if (!this.tabstatus == "") {
				data.search.push({
					key: "status",
					value: this.tabstatus,
					op: "equal",
				});
			}
			if (this.typevalue == "0") {
				data.search.push({
					key: "vr_type",
					value: this.typevalue,
					op: "equal",
				});
			}
			if (this.typevalue) {
				data.search.push({
					key: "vr_type",
					value: this.typevalue,
					op: "equal",
				});
			}
			if (this.catvalue && this.catvalue.length) {
				data.search.push({
					key: "cat_id",
					value: this.catvalue[this.catvalue.length - 1],
					op: "equal",
				});
			}
			if (this.brandvalue) {
				data.search.push({
					key: "brand_id",
					value: this.brandvalue,
					op: "equal",
				});
			}
			if (this.createvalue) {
				data.search.push({
					key: "created_at",
					"value": [this.common.timeStampTurnTime(this.createvalue[0]), this.common.timeStampTurnTime(this.createvalue[1])],
					op: "between",
				});
			}
			if (this.groupvalue) {
				data.search.push({
					key: "group",
					value: this.groupvalue,
					op: "equal",
				});
			}
			if (this.sourcevalue) {
				data.search.push({
					key: "source",
					value: this.sourcevalue,
					op: "equal",
				});
			}
			if (this.low_sales && this.high_sales) {
				let pricelist = [];
				if (Number(this.high_sales) <= Number(this.low_sales)) {
					this.$message({
						showClose: true,
						message: "商品销量区间输入有误",
						type: "warning",
						offset: 200,
						duration: 1000,
					});
					return false;
				}
				pricelist.push(this.low_sales, this.high_sales);
				data.search.push({
					key: "virtual_sales",
					value: pricelist,
					op: "between",
				});
			}
			if (this.low_price && this.high_price) {
				let pricelist = [];
				if (Number(this.high_price) <= Number(this.low_price)) {
					this.$message({
						showClose: true,
						message: "商品价格区间输入有误",
						type: "warning",
						offset: 200,
						duration: 1000,
					});
					return false;
				}
				pricelist.push(this.low_price, this.high_price);
				data.search.push({
					key: "price",
					value: pricelist,
					op: "between",
				});
			}

			return data;
		},
		goodslistapi() {

			// //console.log(data)
			// return false
			this.$get(this.$apis.goodsList + this.mobileActive, this.extractSearch())
				.then((res) => {
					// //console.log(res)
					if (res.code == 200) {
						for (let i = 0; i < res.data.list.length; i++) {
							res.data.list[i].price = Number(res.data.list[i].price);
						}
						this.tableData = res.data.list;
						this.pagesNumber = res.data.total;
						this.total_entry = res.data.total;
					} else {
						this.common.message(this, res.message);
					}
				})
				.catch((err) => {
					// //console.log(err)
					this.common.message(this, err.message);
				});
		},
		// 修改商品名称
		modifyname(id, name) {
			if (!name) {
				this.$message({
					showClose: true,
					message: "商品名称不可为空",
					type: "warning",
					offset: 200,
					duration: 1000,
				});
				return false;
			}
			let data = {
				name: name,
			};
			this.$put(this.$apis.modifyGoodname + id, data)
				.then((res) => {
					// //console.log(res)
					if (res.code == 200) {
						this.$refs.goods_table.click();
					} else {
						this.common.message(this, res.message);
					}
					// this.tableloading = false;
				})
				.catch((err) => {
					// //console.log(err)
					this.common.message(this, err.message);
				});
		},
		// 获取商品分类
		argumentCatlist() {
			this.$get(this.$apis.catList)
				.then((res) => {
					if (res.code == 200) {
						this.catlist = this.getTreeData(res.data.list);
					} else {
						this.common.message(this, res.message);
					}
				})
				.catch((err) => {
					// //console.log(err)
					this.common.message(this, err.message);
				});
		},
		getTreeData(data) {
			for (var i = 0; i < data.length; i++) {
				if (data[i].children.length < 1) {
					data[i].children = undefined;
				} else {
					this.getTreeData(data[i].children);
				}
			}
			return data;
		},
		// 获取品牌
		brandlistapi() {
			let data = {
				page: this.brandPage,
				limit: 10,
				search: [],
			};
			if (this.brandText) {
				data.search.push({
					key: "name",
					value: this.brandText,
					op: "search"
				});
			}
			this.$get(this.$apis.brandList, data)
				.then((res) => {
					// //console.log(res)
					if (res.code == 200) {
						this.brandlist = res.data.list;
						this.brandtotal_entry = res.data.total;
					} else {
						this.common.message(this, res.message);
					}
				})
				.catch((err) => {
					// //console.log(err)
					this.common.message(this, err.message);
				});
		},

		handclassfly(val) {
			this.brandPage = val;
			this.brandlistapi();
		},
		remoteMethoded(e) {
			this.brandText = e;
			this.brandlistapi();
		},
		templateTagChange(val) {
			if (val === false) {
				this.brandlist = []
			} else {
				this.brandText = '';
				this.brandlistapi();
			}
		},
		amendBrand() {
			let data = {
				page: this.brandAmendPage,
				limit: 10,
				search: [],
			};
			if (this.brandamendText) {
				data.search.push({
					key: "name",
					value: this.brandamendText,
					op: "search"
				});
			}
			this.$get(this.$apis.brandList, data)
				.then((res) => {
					// //console.log(res)
					if (res.code == 200) {
						this.brandAmendlist = res.data.list;
						this.brandtotal_amend = res.data.total;
					} else {
						this.common.message(this, res.message);
					}
				})
				.catch((err) => {
					// //console.log(err)
					this.common.message(this, err.message);
				});
		},

		amendclassfly(val) {
			this.brandAmendPage = val;
			this.amendBrand();
		},
		remoteAmendhoded(e) {
			this.brandAmendPage = 1;
			this.brandamendText = e;
			this.amendBrand();
		},
		amendTagChange(val) {
			if (val === false) {
				this.brandAmendlist = []
			} else {
				this.brandAmendPage = 1;
				this.brandamendText = '';
				this.amendBrand();
			}
		},
		// 获取分组
		grouplistapi() {
			let data = {
				search: [{
					key: "name",
					value: this.keywordeds,
					op: "search",
				},],
				page: 1,
				limit: 100,
			};
			this.$get(this.$apis.groupList, data)
				.then((res) => {
					// //console.log(res)
					if (res.code == 200) {
						this.grouplist = res.data.list;
					} else {
						this.common.message(this, res.message);
					}
					// this.tableloading = false;
				})
				.catch((err) => {
					// //console.log(err)
					this.common.message(this, err.message);
				});
		},
		groupsearch() {
			let searchtext = this.$refs.groupsearchtext.value;
			this.keywordeds = searchtext;
			this.grouplistapi();
		},
		// 上架
		grounding(val, scope) {
			this.idlist = [];
			let id = scope.row.id;
			if (val) {
				this.idlist.push(id);
				//console.log(this.idlist, scope)
				this.groundapi(this.idlist, scope);
			} else {
				this.idlist.push(id);
				this.offshelfapi(this.idlist, scope);
			}
		},
		// 批量上架
		groundlist() {
			this.idlist = [];
			if (!this.multipleSelection.length) {
				this.$message({
					showClose: true,
					message: "请选择需要上架的商品",
					type: "warning",
					offset: 200,
					duration: 1000,
				});
				return false;
			}
			for (let i = 0; i < this.multipleSelection.length; i++) {
				this.idlist.push(this.multipleSelection[i].id);
			}
			this.groundapi(this.idlist);
		},
		// 批量下架
		allshelf() {
			this.idlist = [];
			if (!this.multipleSelection.length) {
				this.$message({
					showClose: true,
					message: "请选择需要下架的商品",
					type: "warning",
					offset: 200,
					duration: 1000,
				});
				return false;
			}
			for (let i = 0; i < this.multipleSelection.length; i++) {
				this.idlist.push(this.multipleSelection[i].id);
			}
			this.offshelfapi(this.idlist);
		},
		// 上架接口
		groundapi(id, scope) {
			let that = this;
			let data = {
				id: id,
			};
			this.$confirm("您确定上架该商品?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			}).then(() => {
				that.$put(that.$apis.grounding, data).then((res) => {
					// //console.log(res);
					if (res.code == 200) {
						that.$message({
							type: "success",
							duration: 1500,
							message: "上架成功",
							onClose: () => {
								this.goodslistapi();
							},
						});
					} else {
						if (scope) {
							scope.row.status = scope.row.status == 0 ? 1 : 0;
						}
						that.common.message(that, res.message);
					}
				})
					.catch((err) => {
						// //console.log(err);
						that.common.message(that, err.message);
					});
			}).catch(() => {
				if (scope) {
					scope.row.status = scope.row.status == 0 ? 1 : 0;
				}
			});
		},
		// 下架接口
		offshelfapi(id, scope) {
			let data = {
				id: id,
			};
			this.$confirm("您确定下架该商品?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					this.$put(this.$apis.offshelf, data)
						.then((res) => {
							// //console.log(res);
							if (res.code == 200) {
								this.$message({
									type: "success",
									duration: 1500,
									message: "下架成功",
									onClose: () => {
										this.goodslistapi();
									},
								});
							} else {
								this.common.message(this, res.message);
							}
						})
						.catch((err) => {
							// //console.log(err);
							this.common.message(this, err.message);
						});
				})
				.catch(() => {
					// this.$message({
					// 	type: 'info',
					// 	message: '已取消删除'
					// });
					if (scope) {
						scope.row.status = scope.row.status == 0 ? 1 : 0;
					}
				});
		},
		// 调价
		changepice() {
			this.idlist = [];
			if (!this.multipleSelection.length) {
				this.$message({
					showClose: true,
					message: "请选择需要调价的商品",
					type: "warning",
					offset: 200,
					duration: 1000,
				});
				return false;
			}
			this.$refs.changepice.modifytype = true;
		},
		showCityName(val) {
			this.$message({
				type: "success",
				duration: 1500,
				message: "调价成功",
				onClose: () => {
					this.goodslistapi();
				},
			});
		},
		// 改分组
		changegroup() {
			this.idlist = [];
			if (!this.multipleSelection.length) {
				this.$message({
					showClose: true,
					message: "请选择需要改分组的商品",
					type: "warning",
					offset: 200,
					duration: 1000,
				});
				return false;
			}
			for (let i = 0; i < this.multipleSelection.length; i++) {
				this.idlist.push(this.multipleSelection[i].id);
			}
			this.grouptype = true;
		},
		sealgroup() {
			if (!this.checkList) {
				this.$message({
					showClose: true,
					message: "请选择分组",
					type: "warning",
					offset: 200,
					duration: 1000,
				});
				return false;
			}
			this.allgroupapi(this.idlist, this.checkList);
		},
		cleargroup() {
			this.keyword = "";
			this.checkList = "";
			this.grouptype = false;
		},
		allgroupapi(id, goodsid) {
			let data = {
				id: id,
				group_id: goodsid,
			};
			this.$confirm("您确定修改商品分组?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					this.$put(this.$apis.allGroup, data)
						.then((res) => {
							if (res.code == 200) {
								this.$message({
									type: "success",
									duration: 1000,
									message: "修改商品分组成功",
									onClose: () => {
										this.goodslistapi();
										this.keyword = "";
										this.checkList = [];
										this.grouptype = false;
									},
								});
							} else {
								this.common.message(this, res.message);
							}
						})
						.catch((err) => {
							// //console.log(err)
							this.common.message(this, err.message);
						});
				})
				.catch(() => {
					// this.$message({
					// 	type: 'info',
					// 	message: '已取消删除'
					// });
				});
		},
		// 删除
		goodsdelect() {
			this.idlist = [];
			if (!this.multipleSelection.length) {
				this.$message({
					showClose: true,
					message: "请选择需要删除的商品",
					type: "warning",
					offset: 200,
					duration: 1000,
				});
				return false;
			}
			for (let i = 0; i < this.multipleSelection.length; i++) {
				this.idlist.push(this.multipleSelection[i].id);
			}
			this.goodsdelectapi(this.idlist);
		},
		goodsdelected(id) {
			this.idlist = [];
			this.idlist.push(id);
			this.goodsdelectapi(this.idlist);
		},
		goodsdelectapi(id) {
			let data = {
				id: id,
			};

			this.$confirm("您确定删除该商品?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					this.$delete(this.$apis.goodsDelete, data)
						.then((res) => {
							// //console.log(res)
							if (res.code == 200) {
								this.$message({
									type: "success",
									duration: 1500,
									message: "删除成功",
									onClose: () => {
										this.goodslistapi();
									},
								});
							} else {
								this.common.message(this, res.message);
							}
						})
						.catch((err) => {
							// //console.log(err)
							this.common.message(this, err.message);
						});
				})
				.catch(() => {
					// this.$message({
					// 	type: 'info',
					// 	message: '已取消删除'
					// });
				});
		},
		// 排序
		changesort(val) {
			let that = this;
			this.goodprop = val.prop;
			if (val.order == "descending") {
				this.goodprop = "price";
				this.goodsort = "desc";
			} else if (val.order == "ascending") {
				this.goodprop = "price";
				this.goodsort = "asc";
			} else if (val.order == null) {
				this.goodprop = "id";
				this.goodsort = "desc";
			}
			that.goodslistapi();
		},

		// 跳转创建商品
		searchtab(item) {
			if (item.action == "redirect") {
				this.$router.push("" + item.url);
			}
		},
		handleClick(tab, event) {
			if (tab.index == 0) {
				this.tabstatus = "";
			}
			if (tab.index == 1) {
				this.tabstatus = "1";
			}
			if (tab.index == 2) {
				this.tabstatus = "0";
			}
			if (tab.index == 3) {
				this.tabstatus = "2";
			}
			this.currentPage = 1;
			this.eachPage = 10;
			this.goodslistapi();
		},
		handleSizeChange(val) {
			this.eachPage = val;
			this.goodslistapi();
		},
		handleCurrentChange(val) {
			this.currentPage = val;
			this.common.backTop();
			this.goodslistapi();
		},
		// 选择所有商品
		allchange(value) {
			if (!value) {
				this.$refs.multipleTable.clearSelection();
			} else {
				this.$refs.multipleTable.toggleAllSelection();
			}
			this.all_checked = value;
		},
		// 单个表格被选中
		singletable(val) {
			this.multipleSelection = val;
			if (val.length == this.tableData.length) {
				this.all_checked = true;
			} else {
				this.all_checked = false;
			}
		},
		// 更多操作
		cascChange(val) {
			let taht = this;
			this.idlist = [];
			if (!this.multipleSelection.length) {
				this.$message({
					showClose: true,
					message: "请选择商品",
					type: "warning",
					offset: 200,
					duration: 1000,
				});
				this.cascadervalue = "请选择";
				return false;
			}
			for (let i = 0; i < this.multipleSelection.length; i++) {
				this.idlist.push(this.multipleSelection[i].id);
			}
			if (val == "class") {
				this.catboxtype = true;
			}
			if (val == "sethot") {
				this.goodshot(1, this.idlist);
				this.cascadervalue = "请选择";
			}
			if (val == "closehot") {
				this.goodshot(0, this.idlist);
				this.cascadervalue = "请选择";
			}
			if (val == "settuijian") {
				this.goodbest(1, this.idlist);
				this.cascadervalue = "请选择";
			}
			if (val == "closetui") {
				this.goodbest(0, this.idlist);
				this.cascadervalue = "请选择";
			}
			if (val == "goodsbrand") {
				this.brandModel = "";
				this.brandVisible = true;
				this.cascadervalue = '请选择';
			}
			if (val == "goodsStock") {
				this.repertoryModel = 0;
				this.repertoryVisible = true;
				this.cascadervalue = "请选择"
			}
		},
		// 设置取消热卖
		goodshot(status, id) {
			let data = {
				switch: status,
				id: id,
			};
			this.$put(this.$apis.setHot, data)
				.then((res) => {
					// //console.log(res)
					if (res.code == 200) {
						this.$message({
							type: "success",
							duration: 1500,
							message: "设置成功",
							onClose: () => {
								this.goodslistapi();
							},
						});
					} else {
						this.common.message(this, res.message);
					}
				})
				.catch((err) => {
					// //console.log(err)
					this.common.message(this, err.message);
				});
		},
		// 设置取消推荐
		goodbest(status, id) {
			let data = {
				switch: status,
				id: id,
			};
			this.$put(this.$apis.setBest, data)
				.then((res) => {
					// //console.log(res)
					if (res.code == 200) {
						this.$message({
							type: "success",
							duration: 1500,
							message: "设置成功",
							onClose: () => {
								this.goodslistapi();
							},
						});
					} else {
						this.common.message(this, res.message);
					}
				})
				.catch((err) => {
					// //console.log(err)
					this.common.message(this, err.message);
				});
		},
		sealcat() {
			if (!this.catvaluetc.length) {
				this.$message({
					showClose: true,
					message: "请选择分类",
					type: "warning",
					offset: 200,
					duration: 1000,
				});
				return false;
			}
			this.allcatapi(this.idlist, this.catvaluetc[this.catvaluetc.length - 1]);
		},
		clearcatbox() {
			this.catvaluetc = "";
			this.cascadervalue = "请选择";
			this.catboxtype = false;
		},
		closeDialog() {
			this.catvaluetc = "";
			this.cascadervalue = "请选择";
			this.catboxtype = false;
		},
		allcatapi(id, catid) {
			let data = {
				id: id,
				cat_id: catid,
			};
			this.$put(this.$apis.allCat, data)
				.then((res) => {
					// //console.log(res)
					if (res.code == 200) {
						this.goodslistapi();
						this.catboxtype = false;
						this.cascadervalue = "请选择";
					} else {
						this.common.message(this, res.message);
					}
				})
				.catch((err) => {
					// //console.log(err)
					this.common.message(this, err.message);
				});
		},
		nameCancel(value) {
			let index = value.row.id;
			// 无奈之操作，如有更好，请替代
			this.$refs.goods_table.click();
		},
		// 搜索
		goodseacrh() {
			this.currentPage = 1;
			this.goodslistapi();
		},
		// 重置
		resetting() {
			this.goodsName = "";
			this.typevalue = "";
			this.catvalue = "";
			this.brandvalue = "";
			this.createvalue = "";
			this.groupvalue = "";
			this.sourcevalue = "";
			this.low_sales = "";
			this.high_sales = "";
			this.low_price = "";
			this.high_price = "";
			this.reload();
			this.goodslistapi();
		},
		goodDetail(scope) {
			let url = scope.row.url;
			if (url) {
				window.open(url, "_blank");
			}
		},
		pullgoods() {
			this.shopPull = true;
			this.$refs.shopList.shoptype = !this.$refs.shopList.shoptype
		},
		pullshop(value) {
			let that = this;
			let data = {
				ids: value
			}
			this.$post(this.$apis.shopImport, data).then((res) => {
				if (res.code == 200) {
					that.currentPage = 1;
					that.goodslistapi()
				} else {
					this.common.message(this, res.message);
				}
			}).catch((err) => {
				this.common.message(this, err.message);
			})
		},
		goodshoplist(value) {
			if (!value) {
				return false
			}
			this.shopPull = false;
			let arr = [];
			value.some((item) => {
				arr.push(item.id)
			})
			this.pullshop(arr)
		},

		// 批量设置品牌
		brandChange() {
			if (!this.brandModel) {
				this.$message({
					showClose: true,
					message: "请选择品牌",
					type: "warning",
					offset: 200,
					duration: 1000,
				});
				return false
			}
			let that = this;
			let data = {
				id: this.idlist,
				brand_id: this.brandModel
			}
			this.$put(this.$apis.batchGoodsBrand, data).then((res) => {
				if (res.code == 200) {
					this.$message({
						showClose: true,
						message: "修改成功",
						type: "success",
						offset: 200,
						duration: 1000,
					});
					that.brandVisible = false;
					that.goodslistapi()
				} else {
					this.common.message(this, res.message);
				}
			}).catch((err) => {
				this.common.message(this, err.message);
			})
		},
		// 批量设置库存
		repertoryChange() {
			let that = this;
			let data = {
				id: this.idlist,
				stock: this.repertoryModel,
			}
			this.$put(this.$apis.batchGoodsStock, data).then((res) => {
				if (res.code == 200) {
					this.$message({
						showClose: true,
						message: "修改成功",
						type: "success",
						offset: 200,
						duration: 1000,
					});
					that.repertoryVisible = false;
					that.goodslistapi()
				} else {
					this.common.message(this, res.message);
				}
			}).catch((err) => {
				this.common.message(this, err.message);
			})
		},
		repertoryInput(val) {
			val = String(val).replace(/[^\d]/g, '')
		},
	},
};
</script>

<style scoped>
.goodtablist /deep/ .el-table th:first-child>.cell {
	padding-left: 14px;
}

.journaltc /deep/ .el-dialog {
	width: 680px;
	border-radius: 4px;
}

.journalbox {
	width: 100%;
	padding: 20px;
	box-sizing: border-box;
	max-height: 460px;
	overflow-y: auto;
}

.journalbox .batchtable {
	padding: 0;
}

.dialog-footer .addressqr.el-button--default:focus:not(.el-button--primary),
.addressqr.el-button--default:hover:not(.el-button--primary) {
	color: #fff !important;
	background: var(--fontColor, #fb6638) !important;
}

.hottj p span {
	cursor: pointer;
}

.hottj p span.active {
	color: var(--fontColor, #fb6638);
}

.catbox /deep/ .el-dialog {
	width: 600px;
	border-radius: 2px;
}

.catboxcon {
	width: 100%;
	padding: 20px;
	box-sizing: border-box;
}

.catboxcon /deep/ .el-cascader {
	width: 100%;
}

.catboxcon /deep/ .el-cascader .el-input {
	width: 100%;
}

.table_head>>>.foot_cascader {
	height: 32px;
	line-height: 32px;
}

.table_head>>>.el-input__inner {
	height: 32px;
	line-height: 32px;
}

.table_head>>>.el-input__suffix .el-input__suffix-inner .el-input__icon {
	line-height: 32px;
	height: 32px;
}

.foot_cascader>>>.el-input {
	height: 32px;
}

::v-deep.foot_cascader>>>.el-input input {
	height: 32px;
}

.goods-label {
	color: #666;
}

.journaltc .el-dialog {
	width: 680px;
	border-radius: 4px;
}

.journalbox {
	width: 100%;
	padding: 20px;
	box-sizing: border-box;
	max-height: 460px;
	overflow-y: auto;
}

.journalbox .batchtable {
	padding: 0;
}

.batchtable {
	width: 100%;
	background: #fff;
	border-radius: 4px;
	box-sizing: border-box;
}

.batchtablecon {
	width: 100%;
}

.batchtablecon table {
	table-layout: fixed;
	width: 100%;
	max-width: 100%;
}

.batchtablecon table tr th {
	background: #f9f9fc;
	padding: 15px 10px;
	box-sizing: border-box;
	text-align: center;
	border-bottom: 1px solid #f2f2f2;
	box-sizing: border-box;
	font-size: 14px;
	color: #333;
	font-weight: bold;
}

.batchtablecon table tr td {
	background: #fff;
	padding: 15px 10px;
	box-sizing: border-box;
	text-align: center;
	font-size: 14px;
	color: #333;
	border-bottom: 1px solid #f2f2f2;
	box-sizing: border-box;
	transition: all 0.3s;
}

.batchtablecon table tr:hover td {
	background: var(--primary-tips, #fff3ef);
}

.batchtablecon table tr td .batchdown {
	color: var(--fontColor, #fb6638);
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	display: block;
}

.dialog-footer .addressqr.el-button--default:focus:not(.el-button--primary),
.addressqr.el-button--default:hover:not(.el-button--primary) {
	color: #fff !important;
	background: var(--fontColor, #fb6638) !important;
}

.hottj p span {
	cursor: pointer;
}

.hottj p span.active {
	color: var(--fontColor, #fb6638);
}

.catbox .el-dialog {
	width: 600px;
	border-radius: 2px;
}

.catboxcon {
	width: 100%;
	padding: 20px;
	box-sizing: border-box;
}

.catboxcon .el-cascader {
	width: 100%;
}

.goods_sales .el-input-number {
	width: 100%;
}

.search_headlineadd .el-input__inner {
	border-radius: 0 4px 4px 0;
}

.search_headlineadd .el-input__inner:focus {
	border-radius: 0 4px 4px 0 !important;
}

.search_headline .el-cascader {
	width: 100%;
}

.goodtablist .el-switch.is-checked .el-switch__core {
	background: var(--fontColor, #fb6638);
	border-color: var(--fontColor, #fb6638);
}

.grounptc /deep/ .el-dialog {
	width: 660px;
	border-radius: 2px;
}

.grounptcbox {
	width: 100%;
	padding: 20px;
	box-sizing: border-box;
}

.grounptcboxup {
	width: 100%;
	padding-bottom: 20px;
	border-bottom: 1px solid #ededed;
	box-sizing: border-box;
}

.grounptcboxup /deep/ .el-input {
	float: left;
	width: 320px;
}

.grounptcboxup /deep/ .el-input .el-input__inner {
	border-radius: 4px 0 0 4px;
}

.grounptcboxup button {
	float: left;
	padding: 0 16px;
	box-sizing: border-box;
	height: 40px;
	border-radius: 0 4px 4px 0;
	font-size: 14px;
	cursor: pointer;
	background: var(--fontColor, #fb6638);
	border: 0;
	color: #fff;
}

.grounpbutlist {
	width: 100%;
	padding: 20px 0 0 0;
	box-sizing: border-box;
}

.grounpbutlist .el-checkbox {
	margin-bottom: 12px;
}

.goodtablist .el-table th.el-table__cell:first-child>.cell {
	padding-left: 14px;
}

/deep/.el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-thumb {
	background: #535353;
}

/deep/.el-table--scrollable-x .el-table__body-wrapper {
	background: transparent;
}

.goods_left {
	width: 134px;
	flex: 0 0 134px;
	background: #fff;
	height: 100vh;
	z-index: 10;
	border-right: 1px solid #eee;
	position: fixed;
	top: 0;
	left: 106px;
	box-sizing: border-box;
	margin-top: 50px;
}

.goods_headline {
	height: 50px;
	line-height: 50px;
	padding-left: 23px;
	font-size: 14px;
	color: #333;
	border-bottom: 1px solid #eee;
	padding-top: 0;
}

.el-menu-vertical-demo {
	position: relative;
	display: block;
	margin: 0;
	padding: 0;
	outline: none;
	list-style: none;
	color: #666;
	font-size: 14px;
	position: relative;
}

.goods_left ul {
	z-index: 10;
	background-color: var(--bgColor);
}

.goods_left ul li {
	width: 100%;
	height: 38px;
	line-height: 38px;
	font-size: 15px;
}

.headlin_active {
	background: #edf2ff;
}

.el-menu-vertical-demo .kamiku {
	height: 38px;
	line-height: 38px;
}

.el-menu-vertical-demo .is-active {
	background-color: rgba(31, 114, 249, 0.1);
	color: #409eff;
}

.kamiku .el-submenu__title {
	height: 38px;
	line-height: 38px;
}

.kamiku ul .el-menu-item {
	height: 38px;
	line-height: 38px;
	font-size: 15px;
	width: 94px;
	padding: 0;
	min-width: 134px;
}

.goods_top_healine {
	height: 50px;
	line-height: 50px;
	background-color: #fff;
	padding: 0 20px;
	flex: 0 0 auto;
	margin-bottom: 24px;
}

.goods_list {
	background: #fff;
}

.list_headline {
	padding: 20px;
}

.headline_add {
	padding-bottom: 20px;
	overflow: hidden;
	box-shadow: inset 0 -1px 0 #efefef;
}

.headline_add>div {
	margin-right: 10px;
}

.headline_add>div:last-child {
	margin-right: 0;
}

.add_btn {
	background: var(--fontColor);
	color: #fff;
	padding: 0 16px;
	height: 38px;
	font-size: 14px;
}

.headline_add .el-button :hover {
	background: var(--fontColor);
	color: #fff;
}

.headline_add .add_btn:hover {
	background: var(--fontColor);
	color: #fff;
}

.headline_add .add_btn:focus {
	background: var(--fontColor);
	color: #fff;
}

.goods_search {
	box-shadow: none;
	margin-bottom: 0px;
	padding-bottom: 3px;
	overflow: hidden;
}

.goods_seek {
	display: table;
	word-spacing: -1em;
}

.goods_nav {
	background: #fff;
	border-radius: 4px;
	position: relative;
	margin: 0px 0 20px 0;
	transition: all 0.2s ease-in-out;
}

.nav_headline .el-tabs--top .el-tabs__header .el-tabs__nav-wrap .el-tabs__nav-scroll .el-tabs__nav>.el-tabs__item {
	padding: 16px;
	padding-left: 16px;
	height: auto;
	line-height: normal;
	color: #666;
}

.nav_headline .el-tabs--top .el-tabs__header .el-tabs__nav-wrap .el-tabs__nav-scroll .el-tabs__nav>.is-active {
	font-weight: 600;
	color: var(--fontColor);
}

.nav_headline .el-tabs--top .el-tabs__header .el-tabs__nav-wrap .el-tabs__nav-scroll .el-tabs__nav .el-tabs__active-bar {
	background-color: var(--fontColor);
}

.table_head {
	margin-bottom: 10px;
	padding-left: 14px;
	box-sizing: border-box;
}

.goods_detail_img {
	width: 50px;
	height: 50px;
	margin-right: 5px;
}

.goods_detail_img img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.table_name {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	flex: 1;
}

.table_name span {
	color: #2f74f5;
	background: #e9f0ff;
	padding: 1px 4px;
	font-size: 14px;
	line-height: 14px;
	font-weight: 400;
	border-radius: 2px;
}

.table_name .pt {
	background: #e9f0ff;
}

.table_name .xn {
	background: #ffebe0;
	color: #ff6b2c;
}

.goods_detail {
	width: 100%;
	cursor: pointer;
}

.table_iconed {
	width: 42px;
}

.goods_detail:hover .goodcompile {
	display: block;
}

.goodcompile:active {
	display: block;
}

.goods_table table th,
table td {
	border: none;
}

.goods_table .el-table .el-table__header-wrapper .el-table__header thead tr th {
	background-color: #f9f9fc;
}

.goods_table .el-table .el-table__header-wrapper {
	background-color: #f9f9fc;
}

.goods_table .el-table .el-table__fixed .el-table__fixed-header-wrapper {
	background-color: #f9f9fc;
}

.el-table th.el-table__cell {
	background-color: #f9f9fc;
	font-weight: 600;
	color: #333;
	padding: 8px 0;
}

.el-table .el-table__fixed .el-table__fixed-body-wrapper .el-table__body tbody .el-table__row .el-table__cell {
	border-right: none;
}

.el-table .el-table__body-wrapper .el-table__body tbody .el-table__row .el-table__cell {
	border-right: none;
}

.empty_data {
	width: 100%;
	padding: 10px 0;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}

.empty_data p {
	margin-top: 16px;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: rgba(0, 0, 0, 0.4);
	line-height: 20px;
}

.recommend p span {
	color: rgb(153, 153, 153);
}

.table_source {
	color: #0aab35;
	background-color: #e5ffec;
	padding: 6px 14px;
	box-sizing: border-box;
	border-radius: 2px;
	display: inline-block;
}

.goodstb {
	color: #ff700a;
	background-color: #fff5de;
}

.goodstm {
	color: #d40000;
	background-color: #ffebeb;
}

.goodsjd {
	color: #ed6a0c;
	background-color: #fff5ed;
}

.goods1688 {
	color: #ffaa00;
	background-color: #feffde;
}

.goodsalbb {
	color: #00c1ff;
	background-color: #ebf8ff;
}

.goodtablist .caozuo {
	padding: 0;
}

.caozuo a:last-child {
	border-right: none;
}

.caozuo div {
	padding: 0 10px;
	border-right: 1px solid var(--fontColor, #fb6638);
	cursor: pointer;
	display: inline-block;
	line-height: 1;
	color: var(--fontColor);
}

.caozuo div:last-child {
	border-right: 0;
}

.table_foot {
	margin-top: 10px;
	display: flex;
	padding-left: 14px;
	box-sizing: border-box;
	justify-content: space-between;
	align-items: center;
}

.grounding {
	height: 32px;
	padding-top: 0;
	padding-bottom: 0;
	margin-left: 8px;
	padding: 12px 10px !important;
	line-height: 0;
}

.grounding:focus {
	background: #fff;
	border: 1px solid #dcdfe6;
	color: #606266;
}

.table_head .el-select.foot_cascadered {
	margin-bottom: 0;
}

.foot_cascader {
	margin-left: 10px;
	min-width: 112px;
	width: 112px;
	height: 32px;
	margin-bottom: 0;
}

.foot_cascader .el-input {
	height: 32px;
}

.foot_cascader .el-input input {
	height: 32px;
}

.foot_cascader /deep/ .el-input--suffix {
	width: 100%;
	line-height: 32px;
	height: 32px;
	display: block;
}

.foot_cascader /deep/ .el-input--suffix .el-input__inner {
	height: 32px;
}

.foot_cascader /deep/ .el-input--suffix .el-input__suffix .el-input__suffix-inner .el-input__icon {
	line-height: 32px;
}

.page_num {
	margin-left: 12px;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #7d7e80;
	line-height: 21px;
}

.main_pages {
	display: flex;
	align-items: center;
}

.main_pages .el-pagination {
	display: flex;
	align-items: center;
}

.main_pages .el-pagination .el-pager {
	display: flex;
	align-items: center;
}

.main_pages .el-pagination .el-pager .number {
	min-width: 32px;
	height: 32px !important;
	line-height: 32px !important;
	background: #ffffff;
	border-radius: 4px;
	border: 1px solid #dcdee0;
	margin-left: 4px;
}

.main_pages .el-pagination .el-pager .number.active {
	color: #fff !important;
	background: var(--fontColor);
	border-color: var(--fontColor, #fb6638) !important;
}

.main_pages .el-pagination .el-pager .number:first-child {
	margin-left: 0;
}
</style>
<style lang="scss" scoped>
.goodcompile {
	position: static;
}

.search_headline {
	height: 40px;

	/deep/.el-input {
		.el-input-group__prepend {
			padding: 0;

			.orderfirstleft {
				.el-select {
					margin: 0;

					.el-input {
						.el-input__inner {
							height: 38px;
							line-height: 38px;
						}
					}
				}
			}
		}
	}
}

.searchGood_name {
	min-width: 384px;
}

.searchGood_name .addinputname {
	width: 274px;
}

/deep/.searchGood_name .el-select .el-input__inner {
	height: 40px !important;
	border-radius: 4px 0 0 4px;
}

/deep/.searchGood_name .el-select .el-input__inner:focus {
	border-radius: 4px 0 0 4px !important;
}

.brand_content {
	margin: 20px 0;

	/deep/.el-select {
		width: 45%;

		.el-input {
			width: 100%;
		}
	}
}

.repertory_content {
	margin: 20px 0;

	/deep/.el-input {
		width: 45%;
	}
}

.purpagebutkong {
	height: 34px;
}

.purpagebut {
	position: absolute;
	bottom: 0;
	padding: 2px 10px;
	box-sizing: border-box;
	left: 0;
	width: 100%;
	border-top: 1px solid #eee;
	background: #fff;
}

.purpagebut .btn-prev {
	float: left;
	min-width: 32px;
}

.purpagebut .btn-next {
	float: right;
	min-width: 32px;
}
</style>
